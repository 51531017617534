import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { id: "domain-view" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BackButton = _resolveComponent("BackButton")!
  const _component_prime_message = _resolveComponent("prime-message")!
  const _component_DomainDetails = _resolveComponent("DomainDetails")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BackButton),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.loadingError)
        ? (_openBlock(), _createBlock(_component_prime_message, {
            key: 0,
            severity: "error",
            closable: false
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Error loading domain, please try again ")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.domain)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString(_ctx.domain.name), 1))
        : _createCommentVNode("", true),
      (_ctx.domain)
        ? (_openBlock(), _createBlock(_component_DomainDetails, {
            key: 2,
            domain: _ctx.domain,
            onReload: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadDomain()))
          }, null, 8, ["domain"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}